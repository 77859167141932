<template>
    <div class="help-box">
        <Header :showMainNav="false" :showUserNav="false" :showLang="false" />
        <iframe :src="formData.url" title="help"></iframe>
    </div>
</template>
<script>
import { defineComponent, reactive, inject } from 'vue'
import Header from '@/views/layout/Header.vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
export default defineComponent({
    components: {
        Header
    },
    setup() {
        const { locale } = useI18n()
        const formData = reactive({
            url: '',
            docId: ''
        })
        const http = inject('$http')
        const route = useRoute()
        const docMap = {
            'zh-CN': {
                PrivacyPolicy: '0fa228e27c9a78f3784596af038118c4',
                EULA: '30816477398c69ae38de183ea28663a5'
            },
            'en-US': {
                PrivacyPolicy: '0fa228e27c9a78f3784596af038118c4',
                EULA: '30816477398c69ae38de183ea28663a5'
            }
        }

        const getDocUrl = () => {
            const docId = docMap[locale.value][route.params.type]
            if (docId) {
                formData.docId = docId
                http.account.getPrivacyDocUrl(docId).then(({ data }) => {
                    formData.url = data.url
                })
            } else {
                formData.url = ''
            }
        }
        getDocUrl()

        return { formData, locale, docMap, getDocUrl }
    }
})
</script>
<style scoped lang="less">
.help-box {
    height: 100%;
}
iframe {
    height: calc(100% - 74px);
    width: 100%;
    border: 0;
    display: inherit;
    margin-top: 6px;
}
.doc-item {
    padding: 0 26px;
    &.active {
        color: #2b32c9;
    }
}
</style>
